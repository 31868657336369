import React, { useEffect, useRef } from 'react'
import useOnScreen from '../hooks/useOnScreen'

const SliderScroll = ({ children, speed = 1, className = '', loadAllChildrenOnMobile = false}) => {

	const containerEl = useRef(null)
	const isOnScreen = useOnScreen(containerEl)
	const imagesContainerEl = useRef(null)

	useEffect(() => {
		if (localStorage.getItem('is-touch') === 'true') {
			// only load first element on mobile
			// especially usefull if this slider scroll has images
			// if (loadAllChildrenOnMobile === false) {
			// 	children.splice(1, children.length - 1)
			// }
			return
		}

		let globalContainerHeight = 0
		let distanceForMoving = 0;
		let distanceToTranslate = 0
		let ww = 0
		let wh = 0
		let newX = 0

		const handleResize = () => {
			ww = window.innerWidth
			wh = window.innerHeight
			globalContainerHeight = wh * children.length * speed;
			containerEl.current.style.height = `${globalContainerHeight}px`
			globalContainerHeight = containerEl.current.getBoundingClientRect().height
			distanceForMoving = globalContainerHeight - wh
			distanceToTranslate = imagesContainerEl.current.getBoundingClientRect().width - (ww * 0.97222)
		}

		const handleScroll = e => {
			const scrollTop = containerEl.current.getBoundingClientRect().top;
			if (scrollTop < 0 && scrollTop > -distanceForMoving) {
				const ratio = Math.abs(scrollTop) / distanceForMoving;
				newX = -ratio * distanceToTranslate
			} else if (scrollTop >= 0) {
				newX = 0;
			} else if (scrollTop <= -distanceForMoving) {
				newX = -distanceToTranslate;
			}

			requestAnimationFrame(loop);
		}

		const loop = () => {
			imagesContainerEl.current.style.transform = `translate3d(${newX}px, 0, 0)`
		}

		if (isOnScreen === true) {
			window.addEventListener('scroll', handleScroll)
			window.addEventListener('resize', handleResize)
			handleResize()
		} else {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
		}
		return () => {
			window.removeEventListener('scroll', handleScroll)
			window.removeEventListener('resize', handleResize)
		}
	}, [isOnScreen, children, speed])

	return (
		<section
		className={`SliderScroll ${className}`}
			ref={containerEl}
		>
			<div className="SliderScroll__Sticky">
				<div className="SliderScroll__Wrapper">
					<div
						className="SliderScroll__Container"
						ref={imagesContainerEl}
					>
						{children}
					</div>
				</div>
			</div>
		</section>
	)
}

export default SliderScroll