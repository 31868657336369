import React, { useContext, useEffect }  from 'react'
import { graphql } from 'gatsby'
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SplitText from '../components/SplitText'
import ParallaxImage from '../components/ParallaxImage'
import Button from '../components/Button'
import SliderScroll from '../components/SliderScroll'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import { RichText } from 'prismic-reactjs'
import Picture from '../components/Picture'

const DesignAndInnovation = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

  const document = data.prismicDesignAndInnovation.data

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter

	const bannerContent = {
		title: document.banner_title,
		text: document.banner_text
	}

	const section1 = {
		image: document.section_1_image,
		title: document.section_1_title,
		text: document.section_1_text
	}

	const section2 = {
		images: document.section_2_images,
		title: document.section_2_title,
		text: document.section_2_text,
		buttonLink: document.section_2_button_link,
		buttonText: document.section_2_button_text,
		bottomImageLeft: document.section_2_bottom_image_left,
		bottomImageRight: document.section_2_bottom_image_right
	}

	const section3 = {
		title: document.section_3_title,
		text: document.section_3_text,
		image: document.section_3_image
	}

	const section4 = {
		title: document.section_4_title,
		textTop: document.section_4_text_top,
		image: document.section_4_image,
		subtitle: document.section_4_subtitle,
		textBottom: document.section_4_text_bottom
	}

	const section5 = {
		isVisible: document.section_5_visible,
		title: document.section_5_title,
		imagesList: document.section_5_images_list.map(item => item.image)
	}
	
	const pageTitle = "Architectural Terracotta - Sunscreens | Shildan Group";
	const metaDescription = "Make a bold statement with our terracotta sunscreens - made with all-natural architectural terracotta for a beautiful, functional, and durable exterior.";
	const metaKeywords = "Architectural terracotta, terracotta sunscreen";

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="design_and_innovation" navigation={prismicNavigation} footer={prismicFooter} />
			<section className="Design__Banner">
				<h1
					className="Design__Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={bannerContent.title.raw} />
					</SplitText>
				</h1>
				<h2
					className="Design__Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={bannerContent.text.raw} />
					</SplitText>
				</h2>
			</section>

			<section className="Design__Section1">
				<ParallaxImage
					className="Design__Section1Image"
					src={section1.image.fluid.src}
					srcSet={section1.image.fluid.srcSet}
					alt={section1.image.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.375vw"
				/>
				<h2
					className="Design__Section1Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section1.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Design__Section1Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section1.text.raw} />
					</SplitText>
				</div>
			</section>

			<section className="Design__Section2">
				{/* <ParallaxImage
					className="Design__Section2Image"
					src={section2.image.fluid.src}
					srcSet={section2.image.fluid.srcSet}
					alt={section2.image.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.375vw"
				/> */}
				<SliderScroll speed={1.25} className="Design__Section2SliderScroll">
					{section2.images.map((item, index) => (
						<div
							className="Design__Section2ImageContainer"
							key={`design-slider-scroll-image-${index}`}
						>
							<Picture
								className="Design__Section2Image"
								src={item.image.fluid.src}
								srcSet={item.image.fluid.srcSet}
								alt={item.image.alt}
								sizes="(max-width: 414px) and (min-height: 500px) 100vw, 94.375vw"
							/>
						</div>
					))}
				</SliderScroll>
				<h2
					className="Design__Section2Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section2.title.raw} />
					</SplitText>
				</h2>
				<div className="Design__Section2InfosRight">
					<div
						className="Design__Section2Text"
						data-is-on-screen
					>
						<SplitText splitBy="words">
							<RichText render={section2.text.raw} />
						</SplitText>
					</div>
					{/*<Button link={`/${section2.buttonLink.type}`} className="Design__Section2Button" modifier="big">
						{section2.buttonText.text}
					</Button>*/}
				</div>
				<ParallaxImage
					className="Design__Section2BottomImageLeft"
					src={section2.bottomImageLeft.fluid.src}
					srcSet={section2.bottomImageLeft.fluid.srcSet}
					alt={section2.bottomImageLeft.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 45.5vw"
				/>
				<ParallaxImage
					className="Design__Section2BottomImageRight"
					src={section2.bottomImageRight.fluid.src}
					srcSet={section2.bottomImageRight.fluid.srcSet}
					alt={section2.bottomImageRight.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 45.5vw"
				/>
			</section>

			<section className="Design__Section3">
				<h2
					className="Design__Section3Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section3.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Design__Section3Text"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section3.text.raw} />
					</SplitText>
				</div>
				<ParallaxImage
					className="Design__Section3Image"
					src={section3.image.fluid.src}
					srcSet={section3.image.fluid.srcSet}
					alt={section3.image.alt}
					sizes="69vw"
				/>
			</section>

			<section className="Design__Section4">
				<h2
					className="Design__Section4Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={section4.title.raw} />
					</SplitText>
				</h2>
				<div
					className="Design__Section4TextTop"
					data-is-on-screen
				>
					<SplitText splitBy="words">
						<RichText render={section4.textTop.raw} />
					</SplitText>
				</div>
				<ParallaxImage
					className="Design__Section4Image"
					src={section4.image.fluid.src}
					srcSet={section4.image.fluid.srcSet}
					alt={section4.image.alt}
					sizes="(max-width: 414px) and (min-height: 500px) 100vw, 44.3vw"
				/>
				<div>
					<div
						className="Design__Section4Subtitle"
						data-is-on-screen
					>
						<SplitText splitBy="lines" hasMask>
							<RichText render={section4.subtitle.raw} />
						</SplitText>
					</div>
					<div
						className="Design__Section4TextBottom"
						data-is-on-screen
					>
						<SplitText splitBy="words">
							<RichText render={section4.textBottom.raw} />
						</SplitText>
					</div>
				</div>
			</section>

			{section5.isVisible &&
				<section className="Design__Section5">
					<h2
						className="Design__Section5Title"
						data-is-on-screen
					>
						<SplitText splitBy="lines" hasMask>
							<RichText render={section5.title.raw} />
						</SplitText>
					</h2>
					<ul className="Design__Section5ImagesList">
						{section5.imagesList.map((image, index) => (
							<li
								className="Design__Section5ImageWrapper"
								key={`section5-image-item-${index}`}
							>
								<Picture
									className="Design__Section5Image"
									src={image.fluid.src}
									srcSet={image.fluid.srcSet}
									alt={image.alt}
									sizes="(max-width: 414px) and (min-height: 500px) 100vw, 20.13vw"
								/>
							</li>
						))}
					</ul>
				</section>
			}

			<Footer footer={prismicFooter.data} showImage={false} />

    </div>
  )
}

export const query = graphql`
	query DesignAndInnovation {
		prismicDesignAndInnovation {
			data {
				banner_title {
					raw
				}
				banner_text {
					raw
				}
				section_1_image {
					fluid {
						src
						srcSet
					}
				}
				section_1_title {
					raw
				}
				section_1_text {
					raw
				}
				section_2_images {
					image {
						alt
						fluid {
							srcSet
							src
						}
					}
				}
				section_2_title {
					raw
				}
				section_2_text {
					raw
				}
				section_2_button_link {
					type
				}
				section_2_button_text {
          text
        }
				section_2_bottom_image_left {
					alt
					fluid {
						srcSet
						src
					}
				}
				section_2_bottom_image_right {
					alt
					fluid {
						srcSet
						src
					}
				}
				section_3_title {
					raw
				}
				section_3_text {
					raw
				}
				section_3_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_4_title {
					raw
				}
				section_4_text_top {
					raw
				}
				section_4_image {
					alt
					fluid {
						src
						srcSet
					}
				}
				section_4_subtitle {
					raw
				}
				section_4_text_bottom {
					raw
				}
				section_5_visible
				section_5_title {
					raw
				}
				section_5_images_list {
					image {
						alt
						fluid {
							src
							srcSet
						}
					}
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default DesignAndInnovation